<template>
  <base-popover
    v-show="auth.loggedIn && details"
    v-slot="{ close, open, opened, toggle }"
    placement="bottom-end"
    close-on-leave
  >
    <base-button
      :aria-expanded="opened"
      aria-controls="loyalty-rewards-info-box"
      @click="toggle"
      @keydown.esc="close"
      @mouseenter="open"
    >
      <span
        class="pointer-events-none flex gap-2 ws-nowrap b px-4 py-2 b-grey-70 "
        style="max-width: 15rem;"
      >
        <span class="truncate" style="max-width: 6.25rem;">
          {{ greeting }}
        </span>
        <span class="fw-bold">
          {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: loyalty.currentReward }) }}
        </span>
      </span>
    </base-button>
    <base-popover-content :id class="z-overlay min-w-xs bg-white p-4 c-grey-10 shadow-xs" @keydown.esc="close">
      <div data-test-id="rewards-info-box">
        <div class="text-md">
          {{ greeting }}
        </div>
        <div class="mt-1 truncate text-base fw-bold">
          {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: loyalty.currentReward }) }}
        </div>
        <loyalty-rewards-details v-if="loyalty.vouchers" :vouchers="loyalty.vouchers" />
      </div>
    </base-popover-content>
  </base-popover>
</template>

<script setup lang="ts">
const { $t } = useNuxtApp()
const { details } = storeToRefs(useProfileStore())
const id = useId()
const auth = useAuthStore()
const loyalty = useLoyaltyStore()

if (auth.loggedIn && !loyalty?.vouchers) loyalty.get()

const greeting = computed(() =>
  details.value?.firstName
    ? replaceAll($t.loyaltyRewardsDetails.greetingWithName, { name: details.value.firstName })
    : $t.loyaltyRewardsDetails.greeting
)
</script>
